import React, { Component } from "react"
import { capitalize, date, person, place, TABLES } from "./helper"
import { onValue, query, ref } from "firebase/database"
import { Announcements, FilterDate } from "./index"
import db from "../firebase"


class RenderAnnouncements extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dateFilter: new Date(),
      dataContainer: {}
    }
  }

  componentDidMount() {
    const { dataContainer } = this.state

    try {
      const tables = Object.keys(TABLES)

      tables.forEach(w => {
        const q = query(ref(db, w))
        onValue(q, (dataSnapshot) => {
          const data = []
          dataSnapshot.forEach((childSnapshot) => {
            data.push(childSnapshot.val())
          })
          dataContainer[w] = data
          this.setState({ dataContainer: dataContainer })
        })
      })

    } catch (error) {
      console.log(error)
    }
  }

  filterData(data) {
    const { dateFilter } = this.state
    return data ? data.filter(item => item.year === dateFilter.getFullYear() && item.month === dateFilter.getMonth()) : []
  }

  render() {
    const { dataContainer, dateFilter } = this.state

    return (
      <div className="render-announcements-container">
        <FilterDate
          date={dateFilter}
          onPrev={() => this.setState({ dateFilter: new Date(dateFilter.setMonth(dateFilter.getMonth() - 1)) })}
          onNext={() => this.setState({ dateFilter: new Date(dateFilter.setMonth(dateFilter.getMonth() + 1)) })}
        />
        <Announcements
          title="🐣 Naissances"
          data={this.filterData(dataContainer["birth"])}
          render={
            (value) => `${capitalize(value.firstname)} au foyer de ${person(value.firstname_mom, value.lastname_mom, value.promo_mom)} et ${person(value.firstname_dad, value.lastname_dad, value.promo_dad)}, le ${date(value.day, value.month, value.year)}`
          }
        />
        <Announcements
          title="💍 Mariages"
          data={this.filterData(dataContainer["marry"])}
          render={
            (value) => `${person(value.firstname_wife, value.lastname_wife, value.promo_wife)} et ${person(value.firstname_husband, value.lastname_husband, value.promo_husband)}, le ${date(value.day, value.month, value.year)} ${place(value.place)}`
          }
        />
        <Announcements
          title="✞ Décès"
          data={this.filterData(dataContainer["death"])}
          render={
            (value) => `${person(value.firstname, value.lastname, value.promo)}, le ${date(value.day, value.month, value.year)} ${place(value.place)}`
          }
        />
      </div>
    )
  }
}

export default RenderAnnouncements
