import React from "react"
import { getListYears } from "./helper"


const GenericInput = ({ inputs, onInputChange }) => {

  const onInputChangeWrapper = (id, value) => {
    document.getElementById(id).style.color = !value ? "#D8D8D8" : "#455880"
    onInputChange(id, value)
  }

  return (
    <>
      <span className="generic-input-title">Merci de bien vouloir renseigner les informations ci-dessous :</span>
      {
        inputs.map((o, i) => {
          if (o["type"] === "select_year") {
            return (
              <>
                <label className="generic-input-label">{o["label"]}</label>
                <div className="generic-input-select-wrapper">
                  <select id={o["id"]} onChange={(e) => onInputChangeWrapper(o["id"], e.target.value)}
                          value={o["value"]}>
                    <option key={`select_year_null`} label="Ex: 2007"/>
                    {
                      getListYears(1900).map((o, i) => (
                        <option key={`select_year_${i}`} value={o}>{o}</option>
                      ))
                    }
                  </select>
                </div>
              </>
            )

          }
          const props = o["type"] === "date" && { "max": new Date().toISOString().split("T")[0] }
          return (
            <>
              <label className="generic-input-label">{o["label"]}</label>
              <input key={i} id={o["id"]} type={o["type"]} placeholder={o["placeholder"]}
                     onChange={(e) => onInputChangeWrapper(o["id"], e.target.value)} {...props}/>
            </>
          )
        })
      }
    </>
  )
}

export default GenericInput
