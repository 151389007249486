import React from "react"

import Next from "../../assets/next.png"
import Previous from "../../assets/previous.png"


const FilterDate = ({ date, onPrev, onNext }) => {

  const today = new Date()
  const isNextShowed = today.getFullYear() === date.getFullYear() ? date.getMonth() < today.getMonth() : true

  return (
    <div className="filter-date-container">
      <img src={Previous} className="arrow" onClick={() => onPrev()}/>
      <span className="filter-date-title">{date.toLocaleString("default", { month: "long", year: "numeric" })}</span>
      <img src={Next} className="arrow" style={{ visibility: !isNextShowed && "hidden" }}
            onClick={() => onNext()}/>
    </div>
  )
}

export default FilterDate
