import React from "react"


const Announcements = ({ title, data, render }) => (
  <div className="announcements-container">
    <h4>{title}</h4>
    <ul className="announcements-list">
      {
        data && data.length ? (
          data.map((value, index) => (
            <li key={index}>{render(value)}</li>
          ))
        ) : (
          <li>Pas de {title.toLowerCase()}.</li>
        )
      }
    </ul>
  </div>
)

export default Announcements
