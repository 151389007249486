import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"


const {
  FIREBASE_API_KEY,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
} = process.env

const app = initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: "amical-st-stan.firebaseapp.com",
  databaseURL: "https://amical-st-stan-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "amical-st-stan",
  storageBucket: "amical-st-stan.appspot.com",
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
})

const db = getDatabase(app);

export default db
