export function capitalize(s) {
  return typeof s !== "string" ? "" : s.charAt(0).toUpperCase() + s.slice(1)
}

export function person(f, l, p) {
  return `${f ? capitalize(f) : ""} ${l ? l.toUpperCase() : ""} ${p ? `(promo ${p})` : ""}`
}

export function place(n) {
  return n ? `à ${capitalize(n)}` : ""
}

export function date(d, m, y) {
  return `${new Date(y, m, d).toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })}`
}

export function getListYears(min) {
  const max = new Date().getFullYear()

  return Array.apply(null, {length: max + 1 - min}).map(function(_, idx) {
    return idx + min;
  }).reverse();
}


export const MARRY_FORM = [
  {
    "id": "firstname_wife",
    "label": "Prénom de la mariée",
    "placeholder": "Ex : Marine",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "lastname_wife",
    "label": "Nom de jeune fille de la mariée",
    "placeholder": "Ex : Martin",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "promo_wife",
    "label": "Année de promo de la mariée (si valable)",
    "placeholder": "Ex : 2007",
    "value": undefined,
    "type": "select_year",
    "mandatory": false,
  },
  {
    "id": "firstname_husband",
    "label": "Prénom du marié",
    "placeholder": "Ex : Martin",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "lastname_husband",
    "label": "Nom du marié",
    "placeholder": "Ex : Dubois",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "promo_husband",
    "label": "Année de promo du marié (si valable)",
    "placeholder": "Ex : 2006",
    "value": undefined,
    "type": "select_year",
    "mandatory": false,
  },
  {
    "id": "date",
    "label": "Date du mariage",
    "placeholder": "Ex : 12/07/2020",
    "value": undefined,
    "type": "date",
    "mandatory": true,
  },
  {
    "id": "place",
    "label": "Lieu du mariage",
    "placeholder": "Ex : Nantes",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
]

export const BIRTH_FORM = [
  {
    "id": "firstname",
    "label": "Prénom de l'enfant",
    "placeholder": "Ex : Marine",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "date",
    "label": "Date de naissance",
    "placeholder": "Ex : 12/07/2020",
    "value": undefined,
    "type": "date",
    "mandatory": true,
  },
  {
    "id": "firstname_mom",
    "label": "Prénom de la mère",
    "placeholder": "Ex : Marine",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "lastname_mom",
    "label": "Nom de jeune fille de la mère",
    "placeholder": "Ex : Martin",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "promo_mom",
    "label": "Année de promo de la mère (si valable)",
    "placeholder": "Ex : 2007",
    "value": undefined,
    "type": "select_year",
    "mandatory": false,
  },
  {
    "id": "firstname_dad",
    "label": "Prénom du père",
    "placeholder": "Ex : Martin",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "lastname_dad",
    "label": "Nom du père",
    "placeholder": "Ex : Dubois",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "promo_dad",
    "label": "Année de promo du père (si valable)",
    "placeholder": "Ex : 2006",
    "value": undefined,
    "type": "select_year",
    "mandatory": false,
  },
]

export const DEATH_FORM = [
  {
    "id": "firstname",
    "label": "Prénom de la personne décédée",
    "placeholder": "Ex : Martin",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "lastname",
    "label": "Nom de la personne décédée",
    "placeholder": "Ex : Dubois",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
  {
    "id": "promo",
    "label": "Année de promo (si valable)",
    "placeholder": "Ex : 2006",
    "value": undefined,
    "type": "select_year",
    "mandatory": false,
  },
  {
    "id": "date",
    "label": "Date de décès",
    "placeholder": "Ex : 12/07/2020",
    "value": undefined,
    "type": "date",
    "mandatory": true,
  },
  {
    "id": "place",
    "label": "Lieu du décès",
    "placeholder": "Ex : Nantes",
    "value": undefined,
    "type": "text",
    "mandatory": true,
  },
]


export const TABLES = {
  birth: 'birth',
  death: 'death',
  marry: 'marry',
};
