import React, { Component } from "react"
import { BIRTH_FORM, DEATH_FORM, MARRY_FORM, TABLES } from "./helper"
import { get, ref, set } from "firebase/database"
import { GenericInput, RadioButtons, FormSteps } from "./index"
import db from "../firebase"

import checkmark from "../../assets/checkmark1.png"


class FormAnnouncement extends Component {

  constructor(props) {
    super(props)

    this.state = {
      category: undefined,
      step: 0,
      forms: this.getPristineForms(),
      actionsDispatcher: {
        0: this.next.bind(this),
        1: this.submit.bind(this),
        2: this.clear.bind(this)
      }
    }

    this.submitRef = React.createRef()
  }

  getPristineForms() {
    return {
      marry: JSON.parse(JSON.stringify(MARRY_FORM)),
      birth: JSON.parse(JSON.stringify(BIRTH_FORM)),
      death: JSON.parse(JSON.stringify(DEATH_FORM))
    }
  }

  next() {
    const { step } = this.state
    this.setState({ step: step + 1 })
  }

  async submit() {
    const { forms, category } = this.state
    this.next()
    try {
      this.submitRef.current.scrollIntoView()
      const data = this.formatData(forms[category])
      const { size: dataId } = await get(ref(db, TABLES[category]))
      await set(ref(db, `${TABLES[category]}/${dataId}`), data)
    } catch (error) {
      console.log(error)
    }
  }

  formatData(form) {
    const formattedData = {}
    form.forEach((item) => item["value"] && (formattedData[item["id"]] = item["value"]))
    const date = new Date(formattedData["date"])
    formattedData["day"] = date.getDate()
    formattedData["month"] = date.getMonth() + 1
    formattedData["year"] = date.getFullYear()
    delete formattedData["date"]
    return formattedData
  }

  onInputChange(id, value) {
    const { forms, category } = this.state
    const inputIndex = forms[category].findIndex(input => input.id === id)

    forms[category][inputIndex]["value"] = value

    this.setState({ forms: forms })
  }

  validateInputs() {
    const { forms, category } = this.state
    let error = false
    forms[category].forEach((input) => {
      if (!input.value && input.mandatory) {
        error = true
      }
    })
    return error
  }

  clear() {
    this.setState({ category: undefined, step: 0, forms: this.getPristineForms() })
  }

  render() {
    const { category, step, forms, actionsDispatcher } = this.state

    let content
    switch (step) {
      case 0:
        content = (
          <RadioButtons
            title="Sélectionnez un choix parmi la liste ci-dessous :"
            values={[
              { value: "birth", label: "Annoncer une naissance" },
              { value: "marry", label: "Annoncer un mariage ou PACS" },
              { value: "death", label: "Annoncer un décès" }
            ]}
            selectedCheckbox={category}
            onSelectedCheckbox={(id) => this.setState({ "category": id === category ? undefined : id })}
          />
        )
        break
      case 1:
        content = (
          <GenericInput inputs={forms[category]} onInputChange={(id, value) => this.onInputChange(id, value)}/>
        )
        break
      case 2:
        content = (
          <>
            <span style={{ textAlign: "center", maxWidth: "250px", margin: "50px 0" }}>Votre annonce à bien été prise en compte</span>
            <img src={checkmark} style={{ height: "116px", width: "116px", marginBottom: "50px" }}/>
          </>
        )
        break
    }

    return (
      <div className="form-container">
        <div className="form-header" ref={this.submitRef}>
          <div className="form-title">
            Postez votre annonce GRATUITEMENT
          </div>
          <div className="form-step-container">
            <FormSteps
              step={step}
              steps={[
                "Choix de l'annonce",
                "l'informations de l'annonce",
                "Validation de l'annonce"
              ]}
            />
          </div>
        </div>
        {content}
        <button className="form-submit-button"
                disabled={(step === 0 && !category) || (step === 1 && this.validateInputs())}
                onClick={() => actionsDispatcher[step]()}>
          {step === 2 ? "Terminer" : "Valider"}
        </button>
        <button className="form-cancel-button" style={{ visibility: step !== 1 && "hidden" }}
                onClick={() => this.clear()}>
          Annuler
        </button>
      </div>
    )
  }
}

export default FormAnnouncement
