import React from "react"


const FormSteps = ({ step, steps }) => (
  <>
    <div id="form-steps-news" className="steps-container__progress">
      <div className="choix step done">
        <span className="text-label">{steps[0]}</span>
      </div>
      <div className={`info step ${step >= 1 && "done"}`}>
        <span className="text-label">{steps[1]}</span>
      </div>
      <div className={`validation step ${step >= 2 && "done"}`}>
        <span className="text-label">{steps[2]}</span>
      </div>
      <span className={`inter-1 link ${step >= 1 && "done"}`}/>
      <span className={`inter-2 link ${step >= 2 && "done"}`}/>
    </div>
  </>
)

export default FormSteps
