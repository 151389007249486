import React from "react"


const RadioButtons = ({ title, values, selectedCheckbox, onSelectedCheckbox }) => (
  <div className="radio-buttons-container">
    <div className="radio-buttons-title-container">
      <span className="radio-buttons-title">{title}</span>
    </div>
    <div className="radio-buttons-inputs-container">
      {values.map((o, i) => (
        <>
          <input className="radio-buttons-inputs" id={o["value"]} type="checkbox"
                 checked={selectedCheckbox === o["value"]}
                 onClick={() => onSelectedCheckbox(o["value"])} readOnly={true}/>
          <label className="radio-buttons-labels">{o["label"]}</label>
        </>
      ))}
    </div>
  </div>
)

export default RadioButtons
