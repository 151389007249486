import React, { Component } from "react"
import Layout from "../components/layout"
import Reasons from "../components/reasons"
import PageHeader from "../components/page-header"

import { RenderAnnouncements, FormAnnouncement } from "../components/history"


export default class news extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const path = this.props.location ? this.props.location.pathname : null;
    return (
      <Layout path={path}>
        <PageHeader data={"News des Anciens"}/>
        <div id="news-des-anciens" className="history">
          <RenderAnnouncements/>
          <div className="history-form-container">
            <FormAnnouncement/>
          </div>
        </div>
        <Reasons/>
      </Layout>
    )
  }
}
